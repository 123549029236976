import { configApi } from '../../../../shared/utils/http-client.js'

export const state = {
	cookieScripts: []
}

export const getters = {
	cookieScripts (state) {
		return state.cookieScripts
	}
}

export const mutations = {
	SET_COOKIESCRIPTS (state, cookieScripts) {
		state.cookieScripts = cookieScripts
	},
	SET_COOKIESCRIPT (state, script) {},
	CREATE_COOKIESCRIPT (state, { script, type }) {
		script.cookieScriptType = type
		state.cookieScripts.push(script)
	},
	UPDATE_COOKIESCRIPT (state, script) {
		const matchingScript = state.cookieScripts.find(
			stateScript =>
				stateScript.cookieScriptChannelId === script.cookieScriptChannelId
		)
		matchingScript.cookieScriptName = script.scriptName
		matchingScript.cookieCategoryName = script.cookieCategoryName
		matchingScript.displayText = script.displayText
		matchingScript.brandName = script.brandName
	},
	DELETE_COOKIESCRIPT (state, id) {
		const index = state.cookieScripts.findIndex(
			script => script.cookieScriptChannelId === id
		)
		// if index = -1 don't remove anything
		if (index !== -1) {
			state.cookieScripts.splice(index, 1)
		}
	}
}

export const actions = {
	getCookieScript ({ commit }, channelId) {
		return configApi.get('/api/cookieScript/' + channelId).then(response => {
			const script = response.data
			commit('SET_COOKIESCRIPT', script)
			return script
		})
	},
	getCookieScripts ({ commit }) {
		return configApi.get('/api/cookieScript').then(response => {
			const cookieScripts = response.data
			commit('SET_COOKIESCRIPTS', cookieScripts)
			return cookieScripts
		})
	},
	createCookieScript ({ commit, rootState }, payload) {
		const script = payload.script
		return configApi
			.post('/api/cookieScript', script)
			.then(response => {
				const newScript = response.data
				const brandState = rootState.brands
				const categoryState = rootState.cookieCategories

				// find brand id that matches script.brandID
				const matchingBrand = brandState.brands.find(
					brand => brand.brandId === script.brandId
				)

				// find category id that matches script.parentCookieCategoryId
				const matchingCategory = categoryState.cookieCategories.find(
					category => category.categoryId === script.parentCookieCategoryId
				)

				script.cookieScriptId = newScript.newCookieScriptId
				script.cookieScriptChannelId = newScript.newChannelId
				script.cookieScriptName = script.scriptName
				script.brandName = matchingBrand.brandName
				script.cookieCategoryName = matchingCategory.categoryName
				script.countOfAssociatedBanners = 0

				commit('CREATE_COOKIESCRIPT', payload)

				return newScript
			})
			.catch(function (error) {
				console.error(error.response.data)
			})
	},
	updateCookieScript ({ commit, rootState }, script) {
		return configApi.put('/api/cookieScript', script).then(response => {
			const updatedScript = response.data
			const categoryState = rootState.cookieCategories

			const matchingCategory = categoryState.cookieCategories.find(
				category => category.categoryId === script.parentCookieCategoryId
			)

			script.cookieCategoryName = matchingCategory.categoryName
			script.brandName = matchingCategory.brandName

			commit('UPDATE_COOKIESCRIPT', script)
			return updatedScript
		})
	},
	deleteCookieScript ({ commit }, id) {
		return configApi
			.delete('/api/CookieScript/' + id)
			.then(response => {
				const deletedCookieScript = response.data
				commit('DELETE_COOKIESCRIPT', id)
				return deletedCookieScript
			})
			.catch(function (error) {
				console.error(error.response.data)
			})
	}
}
